<template>
  <span class="brand-logo">
    <b-avatar
      :src="require('@/assets/images/logo/logo_70x70.png')"
      alt="logo"
    />
  </span>
</template>
<script>
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
}
</script>
